.navbar {
  padding: 0.5em;
  background-color: #000000;
  color: white;
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;
}

.nav-title {
  margin: 0;
  font-size: 1.4rem;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 1em;
}

.update-button-wrapper {
  position: relative;
  display: inline-block;
}

.update-button {
  padding: 0.5em 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.25em;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  min-width: 120px;
}

.update-button:hover {
  background-color: #0056b3;
}

.update-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.8;
}

/* Add tooltip styles */
.update-button:disabled:hover {
  cursor: not-allowed;
}

.update-button::after {
  display: none;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translateY(-10%);
  background-color: #fff;
  min-width: fit-content;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  z-index: 1000;
  border-radius: 4px;
  max-height: 50vh;
  overflow-y: auto;
}

.profile-dropdown {
  left: 0;
  width: 125px;
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #cccbcb;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  position: relative;
}

.profile-image {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image img {
  width: 90%;
  object-fit: cover;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: bold;
  font-size: 0.9rem;
  padding-right: 20px;
}

.user-role {
  font-size: 0.8rem;
  opacity: 0.8;
}

.hamburger {
  display: flex;
  padding: 0.8em 0em;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  justify-content: center;
  margin-right: 0.6em;
}

.hamburger span {
  width: 25px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

@media screen and (max-width: 900px) {
  .nav-container {
      flex-direction: column;
      align-items: flex-start;
      display: none;
  }

  .nav-links {
      flex-direction: column;
      background-color: #000;
      gap: 1rem;
      align-items: flex-start;
      padding: 0;
  }

  .nav-container.active {
      display: flex;
  }

  .dropdown-content {
      position: static;
      box-shadow: none;
      background-color: #333;
      margin-top: 0.5rem;
      font-size: 0.9rem;
  }

  .dropdown-content a {
      color: white;
  }

  .dropdown-content a:hover {
      background-color: #444;
  }

  .user-profile {
      flex-direction: column;
      align-items: flex-start;
  }

  .profile-info {
      display: none;
  }

  .nav-right {
      flex-direction: column;
      gap: 0.5em;
  }

  .update-button {
      width: 100%;
  }
}