/* login.css */
body {
    margin: 0;
    padding: 0;
    font-family: 'Jost', sans-serif;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
}

.main {
    width: 400px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 5px 20px 50px #000;
    overflow: hidden;
    padding: 30px;
    backdrop-filter: blur(10px);
}

.logo-container {
    text-align: center;
    margin-bottom: 30px;
}

.logo img {
    width: 150px;
    height: auto;
    border-radius: 10px;
}

.form-container {
    padding: 20px;
}

.form-group {
    margin-bottom: 25px;
}

.select-label {
    color: #fff;
    font-size: 0.9em;
    margin-bottom: 8px;
    display: block;
}

.form-input {
    width: 100%;
    height: 40px;
    background: #e0dede;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

select.form-input {
    cursor: pointer;
}

.submit-button {
    width: 100%;
    height: 45px;
    margin: 20px 0;
    color: #fff;
    background: #573b8a;
    font-size: 1em;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s ease-in;
}

.submit-button:hover {
    background: #6d44b8;
}

.submit-button:disabled {
    background: #8e8e8e;
    cursor: not-allowed;
}

.error-message {
    background: rgba(255, 0, 0, 0.1);
    color: #fff;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid rgba(255, 0, 0, 0.3);
}

.footer {
    text-align: center;
    color: #fff;
    margin-top: 30px;
    font-size: 0.9em;
}

.footer-link {
    color: #a991e6;
    text-decoration: none;
    margin: 0 5px;
}

.footer-link:hover {
    text-decoration: underline;
}

/* Animation for form transitions */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

.form-container form {
    animation: fadeIn 0.5s ease-out;
}

/* Responsive design */
@media (max-width: 480px) {
    .main {
        width: 90%;
        margin: 20px;
    }
}