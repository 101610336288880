.chart-container {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    max-width: 100%;
  }
  
  .chart-content {
    height: 500px;
    width: 100%;
  }
  
  @media (max-width: 900px) {
    .chart-container {
      margin: 10px;
      padding: 10px;
    }
  
    .chart-content {
      height: 400px;
    }
  }