.sub-salon-container {
    padding: 2em;
    background-color: #f5f5f5;
    
}
  
.sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
}
  
.sub-new-category-btn {
    background-color: #1e88e5;
    color: white;
    border: none;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
}
  
.sub-filters {
    display: flex;
    gap: 1em;
    margin-bottom: 2em;
}
  
.sub-status-filter,
.sub-master-category-filter,
.sub-search-input {
    padding: 0.75em;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.875rem;
}
  
.sub-search-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
}
  
.sub-search-input {
    flex-grow: 1;
}
  
.sub-search-btn {
    padding: 0.75em;
    background: none;
    border: 1px solid #ddd;
    border-left: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}
  
.sub-search-icon {
    width: 1.25em;
    height: 1.25em;
}
  
.sub-categories-table {
    max-width: 100%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
  
.sub-categories-table th,
.sub-categories-table td {
    padding: 0.4em;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
  
.sub-categories-table th {
    background-color: #010101;
    font-weight: 600;
    font-size: 0.875rem;
}

.sub-categories-table td{
    padding: 0.4em;
    text-align: left;
    border-bottom: 1px solid #ddd;
    vertical-align: top;
}
  
.sub-package-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.sub-package-list li {
    margin-bottom: 0.5em;
    font-size: 0.875rem;
}
  
.sub-package-list li:before {
    content: "✓";
    margin-right: 0.5em;
    color: #4caf50;
}
  
.sub-status-badge {
    padding: 0.35em 0.75em;
    border-radius: 1em;
    font-size: 0.85rem;
    text-transform: capitalize;
    display: inline-flex;  
    align-items: center;   
    justify-content: center;
    margin-top: 0.25em; 
}
  
.sub-status-badge.active {
    background-color: #e8f5e9;
    color: #2e7d32;
}
  
.sub-status-badge.inactive {
    background-color: #ffebee;
    color: #c62828;
}
  
.sub-action-buttons {
    display: flex;
    gap: 0.5em;
    align-items: center;
    flex-wrap: nowrap;
    min-height: 2.5em;
}
  
.sub-action-buttons button {
    padding: 0.5em 1em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85rem;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
  
.sub-action-buttons button:first-child,
.sub-action-buttons button:nth-child(2) {
    background-color: #1e88e5;
    color: white;
}
  
.sub-edit-btn {
    background-color: #2196f3;
    color: white;
}
  
.sub-delete-btn {
    background-color: #dc3545;
    color: white;
}
  
.sub-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.sub-modal-content {
    background-color: white;
    padding: 2em;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
}
  
.sub-modal-content h3 {
    margin-bottom: 1em;
    font-size: 1.25rem;
}
  
.sub-modal-content input {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 1em;
}
  
.sub-modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
}
  
.sub-modal-buttons button {
    padding: 0.85em 1.5em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
}
  
.sub-modal-buttons button:first-child {
    background-color: #1e88e5;
    color: white;
}
  
.sub-modal-buttons button:last-child {
    background-color: #dc3545;
    color: white;
}