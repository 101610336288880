.hub_master_container {
    padding: 1.5em;
    font-family: Arial, sans-serif;
}

.hub_master_header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.hub_master_title {
    font-size: 1.5rem;
    margin: 0;
}

.hub_master_buttons {
    display: flex;
    gap: 1em;
    
}

.hub_master_button {
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 1em;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.875rem;
}

.hub_master_button_blue {
    background-color: #3178c6;
    color: white;
}

.hub_master_button_icon {
    width: 1em;
    height: 1em;
}

.hub_master_filters {
    display: flex;
    gap: 1em;
    margin-bottom: 1.5em;
}

.hub_master_select {
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 0.25em;
    min-width: 15em;
    font-size: 0.875rem;
    background-color: white;
}

.hub_master_select option {
    padding: 0.5em;
}

.hub_master_select option:first-child {
    color: #666;
}

.hub_master_table_container {
    overflow-x: auto;
}

.hub_master_table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.875rem;
}

.hub_master_table th,
.hub_master_table td {
    padding: 0.45em;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.hub_master_table th {
    background-color: #000;
    color: white;
    font-weight: normal;
}

.hub_master_table tr:nth-child(even) {
    background-color: #f5f5f5;
}

.hub_master_edit_button {
    background-color: #ffd699;
    border: none;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.875rem;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .hub_master_header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1em;
    }
    
    .hub_master_filters {
        flex-direction: column;
    }
    
    .hub_master_select {
        width: 100%;
    }
}