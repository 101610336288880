.service-packages {
    width: 100%;
    padding: 1em 2em;
    background-color: #f8f9fa;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header h2 {
    font-size: 1.4rem;
    color: #333;
  }
  
  .new-budget-btn {
    padding: 0.5em 1em;
    font-size: 1rem;
    color: #fff;
    background-color: #346cb0;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
  }
  
  .new-budget-btn:hover {
    background-color: #0056b3;
  }
  
  .packages-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .packages-table th, .packages-table td {
    padding: 0.5em;
    text-align: left;
    border: 1px solid #dee2e6;
  }
  
  .packages-table th {
    background-color: #181a1b;
    color: #fff;
    font-size: 1rem;
  }
  
  .packages-table td {
    font-size: 0.9rem;
    color: #333;
  }
  
  .packages-table td ul {
    padding-left: 0em;
    margin: 0;
  }
  
  .packages-table td ul li {
    list-style: none;
    font-size: 1rem;
    margin-bottom: 0.3em;
  }
  
  .status {
    padding: 0.2em 0.8em;
    font-size: 1rem;
    border-radius: 0.25em;
  }
  
  .status.active {
    color: #fff;
    background-color: #007bff;
  }
  
  .status.inactive {
    color: #fff;
    background-color: #ffc107;
  }
  
  .edit-btn {
    padding: 0.3em 1em;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
  }
  
  .edit-btn:hover {
    background-color: #0056b3;
  }
  