.service-container {
    padding: 1.5em;
    font-family: Arial, sans-serif;
  }
  
  .service-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;
  }
  
  .service-header h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .service-actions {
    display: flex;
    gap: 0.5em;
  }
  
  .service-actions button {
    padding: 0.5em 1em;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .add-new,.secondary-category {
    background-color: #3474eb;
    color: white;
  }
  
  .service-type {
    background-color: rgba(172, 146, 0, 0.692);
    color: white;
  }
  
  .service-filters {
    display: flex;
    gap: 1em;
    margin-bottom: 1.5em;
  }
  
  .filter-select, .search-input {
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 0.25em;
    font-size: 0.875rem;
    min-width: 10em;
  }
  
  .service-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5em;
  }
  
  .service-table th,
  .service-table td {
    padding: 0.40em;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 0.875rem;
  }
  
  .service-table th {
    background-color: #050505;
    font-weight: 500;
  }
  
  .service-image {
    width: 2.5em;
    height: 2.5em;
    background-color: #f0f0f0;
    border-radius: 0.25em;
  }
  
  .original-price {
    text-decoration: line-through;
    color: #999;
    margin-right: 0.5em;
  }
  
  .sale-price {
    color: #333;
    font-weight: 600;
  }
  
  .status-badge, .sale-badge {
    display: inline-block;
    padding: 0.35em 0.5em;
    border-radius: 0.25em;
    font-size: 0.85rem;
    margin-right: 0.5em;
    font-weight: 600;
  }
  
  .status-badge {
    color: #e6f4ea;
    background: #2cb058;
  }
  
  .sale-badge {
    color: #e8f0fe;
    background: #1a73e8;
  }
  
  .action-buttons {
    display: flex;
    gap: 0.5em;
  }
  
  .edit-button, .delete-button {
    padding: 0.35em 0.75em;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 600;
  }
  
  .edit-button {
    background-color: #3474eb;
    color: white;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
  }
  
  .show-more {
    display: block;
    margin: 0 auto;
    padding: 0.5em 1em;
    background-color: #3474eb;
    color: white;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  @media (max-width: 1024px) {
    .service-filters {
      flex-wrap: wrap;
    }
    
    .filter-select, .search-input {
      flex: 1 1 calc(33.333% - 1em);
      min-width: initial;
    }
  }
  
  @media (max-width: 768px) {
    .service-container {
      padding: 1em;
    }
    
    .service-table {
      overflow-x: auto;
      display: block;
    }
    
    .filter-select, .search-input {
      flex: 1 1 calc(50% - 1em);
    }
  }