.settings-container {
  padding: 20px;
  width: 100%;
}

.role-selector {
  margin-bottom: 30px;
}

.role-dropdown {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
  margin-top: 10px;
}

.controls-container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.controls-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.controls-table th,
.controls-table td {
  padding: 12px;
  border-bottom: 1px solid #eee;
  text-align: left;
}

.controls-table th {
  background-color: #f8f9fa;
  font-weight: 600;
}

.loading {
  padding: 20px;
  text-align: center;
  color: #666;
}

.error-message {
  padding: 20px;
  color: #dc3545;
  background-color: #f8d7da;
  border-radius: 4px;
  margin-bottom: 20px;
}