.sp-container {
    padding: 1em;
}

.sp-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;
}



.sp-actions {
    display: flex;
    gap: 0.5em;
}

.sp-category-btn, .sp-add-btn {
    padding: 0.5em 1em;
    border: none;
    border-radius: 0.25em;
    color: white;
    cursor: pointer;
}

.sp-category-btn {
    background-color: #0056b3;
}

.sp-add-btn {
    background-color: #28a745;
}

.sp-search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
}

.sp-total {
    font-size: 0.875rem;
    margin: 0;
}

.sp-search {
    min-width: 40em;
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 0.25em;
}

.sp-table-container {
    overflow-x: auto;
}

.sp-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.875rem;
}

.sp-table th, 
.sp-table td {
    padding: 0.5em;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.sp-table th {
    background-color: #000;
    color: white;
}

.sp-table tr:nth-child(even) {
    background-color: #f8f9fa;
}

.status-badge {
    padding: 0.35em 0.75em;
    border-radius: 1em;
    font-size: 0.85rem;
    text-transform: capitalize;
}

.status-badge.active {
    background-color: #28a745;
    color: white;
}

.status-badge.inactive {
    background-color: #dc3545;
    color: white;
}

.action-buttons {
    display: flex;
    gap: 0.25em;
}

.action-buttons button {
    padding: 0.35em 0.5em;
    border: none;
    border-radius: 0.25em;
    font-size: 0.85rem;
    cursor: pointer;
    color: white;
    font-weight: 600;
}

.btn-active {
    background-color: #007bff;
}

.btn-inactive {
    background-color: #6c757d;
}

.btn-edit {
    background-color: #ffc107;
}

.btn-delete {
    background-color: #dc3545;
}

@media screen and (max-width: 1024px) {
    .sp-table-container {
        overflow-x: auto;
    }
    
    .action-buttons {
        flex-wrap: wrap;
    }
}