.users-container {
    padding: 20px;
    background-color: #f5f5f5;
}

.users-header {
    margin-bottom: 20px;
}

.users-header h1 {
    color: #333;
    font-size: 24px;
}

.users-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 30px;
}

.form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
}

.form-group {
    flex: 1;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-weight: 500;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.submit-button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.submit-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.error-message {
    color: #dc3545;
    padding: 10px;
    margin: 10px 0;
    background: #ffe6e6;
    border-radius: 4px;
}

.success-message {
    color: #28a745;
    padding: 10px;
    margin: 10px 0;
    background: #e6ffe6;
    border-radius: 4px;
}

.users-list {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.users-list table {
    width: 100%;
    border-collapse: collapse;
}

.users-list th,
.users-list td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.users-list th {
    background: #f8f9fa;
    font-weight: 600;
}

.users-list tr:hover {
    background: #f5f5f5;
}

.edit-button,
.delete-button {
    padding: 6px 12px;
    margin: 0 4px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.edit-button {
    background: #28a745;
    color: white;
}

.delete-button {
    background: #dc3545;
    color: white;
}

@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
        gap: 10px;
    }
}

.filters-container {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}

.search-input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.role-filter {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    min-width: 150px;
}

.edit-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.edit-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
}

.button-group {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.button-group button {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button-group button[type="submit"] {
    background: #28a745;
    color: white;
}

.button-group button[type="button"] {
    background: #dc3545;
    color: white;
}