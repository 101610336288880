.experts-container {
    width: 100%;
    padding: 1em;
  }
  
  .experts-container h1 {
    font-size: 1.5rem;
    margin-bottom: 1em;
    color: #333;
  }
  
  .table-container {
    width: 100%;
    overflow-x: auto;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.1);
    border-radius: 0.25em;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  th {
    background-color: #000;
    color: #fff;
    text-align: left;
    padding: 1em;
    font-size: 0.875rem;
    font-weight: 600;
    white-space: nowrap;
  }
  
  td {
    padding: 0.4em;
    font-size: 0.875rem;
    color: #333;
    border-bottom: 0.0625em solid #f0f0f0;
  }
  
  tr:nth-child(odd) {
    background-color: #f8f8f8;
  }
  
  tr:hover {
    background-color: #f0f0f0;
  }
  
  @media screen and (max-width: 768px) {
    .table-container {
      margin: 0 -1em;
    }
    
    td, th {
      padding: 0.75em;
    }
    
    .experts-container {
      padding: 0.75em;
    }
  }