.customer-form-container {
    padding: 2em;
    background-color: white;
    margin: 2em;
    border-radius: 6px;
    box-shadow: 0 0.063em 0.3em rgba(0,0,0,0.1);
  }
  
  .customer-form-container h2 {
    font-size: 1.2rem;
    margin-bottom: 0.8em;
    color: #333;
    margin-top: 0;
    font-weight: 500;
  }
  
  .customer-form-container form{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:1em;
  }
  
  .customer-form-container .form-group {
    flex: 1;
    min-width: 250px;
    background-color: white;
    border: none;
    margin-bottom: 0;
    padding: 0;
  }
  
  .customer-form-container .form-group label {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.3em;
    color: #333;
  }
  
  .customer-form-container .required {
    color: #ff0000;
  }
  
  .customer-form-container .form-group input,
  .customer-form-container .form-group select {
    width: 100%;
    padding: 0.75em;
    font-size: 0.875rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .customer-form-container .form-group select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75em center;
    background-size: 1em;
    padding-right: 2.5em;
  }
  
  .customer-form-container .form-group input:focus,
  .customer-form-container .form-group select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .customer-form-container .form-group input::placeholder {
    color: #999;
  }
 

  .booking-container {
    margin: 2em;
    padding: 2em;
    background-color: #ffffff;
    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .top-section {
    margin-bottom: 1em;
    width: 50%;
  }
  
  .buttons {
    display: flex;
    gap: 0.5em;
    margin-bottom: 0.5em;
  }
  
  .add-service,
  .add-package {
    padding: 0.5em 1em;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .add-service {
    background-color: #3178c6;
    color: white;
  }
  
  .add-package {
    background-color: #f0ad4e;
    color: white;
  }
  
  .search-dropdown {
    position: relative;
    margin-bottom: 0.5em;
  }
  
  .search-dropdown input {
    width: 100%;
    padding: 0.5em;
    border: 0.0625em solid #ddd;
    border-radius: 0.25em;
    font-size: 1rem;
  }
  
  .dropdown-items {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 0.0625em solid #ddd;
    border-radius: 0.25em;
    max-height: 15em;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .dropdown-item {
    padding: 0.5em;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f5f5f5;
  }
  
  .items-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  
  .items-table th,
  .items-table td {
    padding: 0.25em;
    border: 0.0625em solid #ddd;
    text-align: left;
  }
  
  .items-table th {
    background-color: #f5f5f5;
    color: black;
  }
  
  .items-table input {
    width: 5em;
    padding: 0.25em;
    border: 0.0625em solid #ddd;
    border-radius: 0.25em;
  }
  
  .cross-button {
    cursor: pointer;
    background-color: red;
    color: white;
    width: 1.25em;
    height: 1.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    font-weight: 600;
  }
  
  .booking-details {
    margin: 1em 0;
  }
  
  .date-selection {
    margin-bottom: 1em;
  }
  
  .date-selection label {
    display: block;
    margin-bottom: 0.25em;
    font-weight: bold;
  }
  
  .date-selection input {
    padding: 0.5em;
    border: 0.0625em solid #ddd;
    border-radius: 0.25em;
  }
  
  .time-slots label {
    display: block;
    margin-bottom: 0.25em;
    font-weight: bold;
  }
  
  .slots-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(8em, 1fr));
    gap: 0.25em;
  }
  
  .slot {
    padding: 0.5em;
    border: 0.0625em solid #ddd;
    border-radius: 0.25em;
    background: none;
    cursor: pointer;
    text-align: center;
    font-size: 0.875rem;
  }
  
  .slot.selected {
    background-color: #3178c6;
    color: white;
    border-color: #3178c6;
  }
  
  .cost-summary {
    margin: 1em 0;
    width: 45%;
  }
  
  .cost-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5em;
    padding: 0.25em 0;
  }
  
  .cost-item label {
    font-weight: bold;
  }
  
  .cost-item input {
    width: 8em;
    padding: 0.25em;
    border: 0.0625em solid #ddd;
    border-radius: 0.25em;
    text-align: right;
  }
  
  .total {
    border-top: 0.125em solid #ddd;
    padding-top: 0.5em;
    font-size: 1.125rem;
  }
  
  .create-lead {
    background-color: #3178c6;
    color: white;
    padding: 0.5em 1em;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 1rem;
    float: right;
  }
  
  .create-lead:hover {
    background-color: #2661a5;
  }