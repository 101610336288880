/* CategoryList.css */
.master-categories {
    padding: 1.5em;
    background-color: #f5f5f5;
  }
  
  .master-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1.5em;
  }
  
  
  
  .new-category-btn {
    background-color: #3B82F6;
    color: white;
    border: none;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .filters {
    display: flex;
    gap: 1em;
    margin-bottom: 1.5em;
  }
  
  .filter-select,
  .search-input {
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 0.25em;
    font-size: 0.875rem;
  }
  
  .filter-select {
    min-width: 8em;
  }
  
  .search-input {
    flex-grow: 1;
  }
  
  .categories-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .categories-table th,
  .categories-table td {
    padding: 1em;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .categories-table th {
    background-color: #000;
    color: white;
    font-weight: normal;
  }
  
  .category-image {
    width: 2.5em;
    height: 2.5em;
    object-fit: cover;
    border-radius: 0.25em;
  }
  
  .status-badge {
    background-color: #3B82F6;
    color: white;
    padding: 0.25em 0.75em;
    border-radius: 1em;
    font-size: 0.75rem;
  }
  
  .action-buttons {
    display: flex;
    gap: 0.5em;
  }
  
  .edit-btn,
  .delete-btn {
    padding: 0.32em 0.75em;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.85rem;
  }
  
  .edit-btn {
    background-color: #3B82F6;
    color: white;
  }
  
  .delete-btn {
    background-color: #EF4444;
    color: white;
  }
  
  .edit-btn:hover,
  .delete-btn:hover {
    opacity: 0.9;
  }