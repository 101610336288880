.blog-container {
    padding: 2em;
}

.blog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
}

.blog-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

.new-blog-btn {
    background-color: #0088cc;
    color: white;
    border: none;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
}

.search-container {
    margin-bottom: 2em;
}

.search-input {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.875rem;
}

.table-container {
    overflow-x: auto;
}

.blog-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
}

.blog-table th,
.blog-table td {
    padding: 1em;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.blog-table th {
    background-color: #000;
    color: white;
    font-weight: 500;
    font-size: 0.875rem;
}

.blog-thumb {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
}

.action-buttons {
    display: flex;
    gap: 0.5em;
}

.action-btn {
    padding: 0.5em 1em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.75rem;
    background-color: #f0f0f0;
}

.action-btn:hover {
    background-color: #e0e0e0;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .blog-container {
        padding: 1em;
    }
    
    .blog-table {
        font-size: 0.875rem;
    }
    
    .blog-table th,
    .blog-table td {
        padding: 0.75em;
    }
}