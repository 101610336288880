/* CouponTable.css */
.coupon-table-container {
    font-size: 1rem;
  }
  
  .coupon-table-search {
    display: block;
    width: 100%;
    padding: 0.5em;
    margin-bottom: 1em;
    font-size: 1rem;
  }
  
  .coupon-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .coupon-table th,
  .coupon-table td {
    padding: 0.75em;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .coupon-table th {
    background-color: #070707;
  }
  
  .coupon-table-action-btn {
    margin-right: 0.5em;
    padding: 0.25em 0.75em;
    font-size: 0.875rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .coupon-table-action-btn:hover {
    background-color: #0056b3;
  }