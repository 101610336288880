.promotional-banner-upload {
    padding: 1em 2.2em;
    background-color: #f6f7f9;
  }
  
  .promotional-banner-upload >h3{
    font-size: 1.3rem;
  }
  
  .banner-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.5em;
    padding: 1em;
    margin-bottom: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .banner-title {
    font-size: 0.9rem;
    margin: 0;
  }
  
  .file-input {
    width: 100%;
    padding: 0.5em;
    border: none;
    border-radius: 0.3em;
    font-size: 1rem;
  }
  
  .submit-btn {
    font-size: 1rem;
    padding: 0.5em 1em;
    background-color: #346cb0;
    color: #fff;
    border: none;
    border-radius: 0.3em;
    cursor: pointer;
  }
  
  .file-upload-container {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 0.3em;
    padding: 0.3em 0.6em;
    width: 100%;
    margin: 0.5em 0em;
  }
  
  .file-label {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #6c757d;
  }
  
  .file-text {
    margin-right: 0.5em;
  }
  
  
  
  .browse-button {
    background-color: #f8f9fa;
    color: #6c757d;
    border: 1px solid #ced4da;
    padding: 0.3em 1em;
    border-radius: 0.3em;
    font-size: 0.9rem;
    cursor: pointer;
  }
  
  
  .browse-button:hover {
    background-color: #e2e6ea;
  }
  