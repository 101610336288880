.categories-container {
    padding: 1.5em;
    background-color: #f5f5f5;
}

.new-category-btn {
    background-color: #3B82F6;
    color: white;
    border: none;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.875rem;
}

.master-filters {
    display: flex;
    gap: 1em;
}

.master-filter-select {
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 0.25em;
    min-width: 10em;
    max-height: fit-content;
    font-size: 0.875rem;
}

.search-container {
    flex-grow: 1;
}

.search-input {
    width: 100%;
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 0.25em;
    font-size: 0.875rem;
}


.table-container {
    background-color: white;
    border-radius: 0.25em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}

/* Table alignment fixes */
.categories-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.categories-table th,
.categories-table td {
    padding: 0.4em;
    text-align: left;
    border-bottom: 1px solid #eee;
    vertical-align: middle;
}

.categories-table th {
    background-color: #000;
    color: white;
    font-weight: normal;
    position: sticky;
    top: 0;
    z-index: 1;
}

.category-cell {
    display: flex;
    align-items: center;
    gap: 1em;
    font-size: 1rem;
    min-height: 3em;
}

.category-image {
    width: 3em;
    height: 3em;
    object-fit: cover;
    border-radius: 0.25em;
    flex-shrink: 0;
}

.sub-categories {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sub-categories li {
    margin-bottom: 0.25em;
    font-size: 0.875rem;
    line-height: 1.4;
}

.status-badge {
    background-color: #3B82F6;
    color: white;
    padding: 0.35em 0.75em;
    border-radius: 1em;
    font-size: 0.875rem;
    display: inline-block;
    white-space: nowrap;
}


.action-buttons {
    display: flex;
    gap: 0.5em;
    align-items: center;
    flex-wrap: nowrap;
    min-height: 2.5em;
}

.action-btn {
    padding: 0.35em 0.75em;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.875rem;
    color: white;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 4em;
}

.action-btn.edit {
    background-color: #3B82F6;
}

.action-btn.delete {
    background-color: #EF4444;
}

.action-btn.view {
    background-color: #10B981;
}

.action-btn:hover {
    opacity: 0.9;
}