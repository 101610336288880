.sidebar{
   display: flex;
   flex-direction: column;
   padding: 0.2em 0.6em;
   width: fit-content;
   align-items: flex-start;
   color:white;
   background-color: black;
   position: fixed;
   top: 0;
   left: 0;
   height: 100vh;
   gap: 1.5em;
   z-index: 1000;
}

.side-icons{
    display: flex;
    justify-content: space-between;
    gap:0.5em;
    align-items: center;
    width: 100%;
}

.side-arrow{
    width: 2rem;
    font-weight: 600;
    cursor: pointer;
}

.side-arrow img{
   width: 100%;
   filter: invert(1) brightness(3);
}

.nav-links {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    font-size: 0.9rem;
  }
  
  .nav-link {
    color: white;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap:0.7em;
  }

  .nav-link img{
    width:2em;
    filter: invert(1) brightness(10);
  }

  
  .nav-item {
    position: relative;
  }

  .nav-item > .dropdown-content{
    background-color: #333;
    
  }

  .nav-item > .dropdown-content >a{
    color: white;
  }

  .nav-item > .dropdown-content >a:hover{
    color:#333;
    background: white;
  }

  .side-text{
    display: flex;
    gap:0.5em;
  }

  .nav-item > .dropdown-content {
    position: absolute;
    left: 100%;
    top: 0;
    min-width: 160px;
    background-color: #333;
    border-radius: 4px;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .nav-item > .dropdown-content .nav-link {
    padding: 8px 16px;
    white-space: nowrap;
  }

  .nav-item > .dropdown-content .nav-link:hover {
    background-color: white;
    color: #333;
  }

  .side-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .side-text span:last-child {
    margin-left: 8px;
  }

  .side-arrow-bottom {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.3rem;
    font-weight: 600;
    transition: all 0.3s ease;
  }

  .side-arrow-bottom img {
    width: 100%;
    filter: invert(1) brightness(3);
  }

  .container {
    display: flex;
  }

  .inner-container {
    margin-left: 60px;
    width: calc(100% - 60px);
    transition: margin-left 0.3s ease;
  }

  .inner-container.expanded {
    margin-left: 185px;
    width: calc(100% - 200px);
  }