.packages-container {
    padding: 1.5em;
    background-color: #fff;
  }
  
  .pack-packages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1em;
  }
  
  .pack-filters {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
  }
  
  .pack-filter-select,
  .pack-package-search-input {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    min-width: 10rem;
  }
  
  .pack-package-search-input {
    flex: 1;
    min-width: 200px;
  }
  
  .pack-action-buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-shrink: 0;
  }
  
  .pack-export-btn,
  .pack-new-package-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .pack-export-btn {
    background-color: #28a745;
    color: white;
  }
  
  .pack-new-package-btn {
    background-color: #007bff;
    color: white;
  }
  
  .packages-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.875rem;
    margin-bottom: 1.5em;
  }
  
  .packages-table th,
  .packages-table td {
    padding: 0.4em;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .packages-table th {
    background-color: #000;
    color: white;
  }
  
  .services-cell {
    max-width: 20em;
  }
  
  .service-item {
    margin: 0.25em 0;
    color: #666;
  }
  
  .read-more-btn {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
    font-size: 0.875rem;
  }
  
  .price-cell {
    white-space: nowrap;
  }
  
  .original-price {
    text-decoration: line-through;
    color: #999;
  }
  
  .discounted-price {
    font-weight: bold;
    color: #28a745;
  }
  
  .savings {
    color: #28a745;
    font-size: 0.75rem;
  }
  
  .status-badge {
    padding: 0.35em 0.5em;
    border-radius: 0.25em;
    font-size: 0.85rem;
    text-transform: capitalize;
    font-weight: 600;
  }
  
  .status-badge.active {
    color: #e8f5e9;
    background-color: #28a745;
  }
  
  .status-badge.inactive {
    background-color: #ffebee;
    color: #dc3545;
  }
  
  .action-cell {
    white-space: nowrap;
  }
  
  .edit-btn,
  .delete-btn {
    padding: 0.35em 0.5em;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 600;
  }
  
  .edit-btn {
    background-color: #007bff;
    color: white;
  }
  
  .delete-btn {
    background-color: #dc3545;
    color: white;
  }
  
  /* Pagination Styles */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-top: 1.5em;
  }
  
  .pagination-numbers {
    display: flex;
    gap: 0.5em;
  }
  
  .pagination-btn,
  .pagination-number {
    padding: 0.5em 1em;
    border: 1px solid #ddd;
    background-color: white;
    cursor: pointer;
    font-size: 0.875rem;
    border-radius: 0.25em;
  }
  
  .pagination-btn:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    color: #999;
  }
  
  .pagination-number.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .pagination-btn:hover:not(:disabled),
  .pagination-number:hover:not(.active) {
    background-color: #f5f5f5;
  }