.contacts-container {
    width: 100%;
    padding: 1em;
  }
  
  .contacts-container h1 {
    font-size: 1.5rem;
    margin-bottom: 1em;
    color: #333;
  }
  
  .table-container {
    width: 100%;
    overflow-x: auto;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.1);
    border-radius: 0.25em;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  th {
    background-color: #000;
    color: #fff;
    text-align: left;
    padding: 1em;
    font-size: 0.875rem;
    font-weight: 600;
  }
  
  td {
    padding: 1em;
    border-bottom: 0.0625em solid #eee;
    font-size: 0.875rem;
    color: #333;
  }
  
  tr:hover {
    background-color: #f8f8f8;
  }
  
  .btn-contact,
  .btn-enquiry {
    padding: 0.5em 1em;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  
  .btn-contact {
    background-color: #ffd699;
    color: #333;
  }
  
  .btn-enquiry {
    background-color: #4a90e2;
    color: #fff;
  }
  
  .btn-contact:hover {
    background-color: #ffc266;
  }
  
  .btn-enquiry:hover {
    background-color: #357abd;
  }