.slot-manager {
    font-family: Arial, sans-serif;
    padding: 2em;
  }
  
  
  
  .new-slot-button {
    background-color: #346cb0;
    color: #fff;
    border: none;
    border-radius: 0.25em;
    padding: 0.5em 1em;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .date-selector {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }
  
  .label {
    font-size: 1rem;
    margin-right: 0.5em;
  }
  
  .date-input {
    font-size: 1rem;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 0.25em;
    flex-grow: 1;
  }
  
  .slot-container {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 1em;
  }
  
  .slot {
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 0.25em;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap:1.5em;
  }
  
  .time-range {
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1em;
  }
  
  .job-acceptance {
    font-size: 1rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .job-booked {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    justify-content: space-between;
    width: 100%;
  }
  
  .job-booked-toggle {
    width: 2em;
    height: 1em;
    background-color: #ccc;
    border-radius: 0.5em;
    position: relative;
    margin-left: 0.5em;
    cursor: pointer;
  }
  
  .job-booked-toggle.on {
    background-color: #346cb0;
  }
  
  .job-booked-toggle::before {
    content: '';
    position: absolute;
    width: 0.8em;
    height: 0.8em;
    background-color: #fff;
    border-radius: 50%;
    top: 50%;
    left: 0.1em;
    transform: translateY(-50%);
    transition: left 0.3s;
  }
  
  .job-booked-toggle.on::before {
    left: 1.1em;
  }
  
  .edit-button {
    cursor: pointer;
  }
  
  .edit-button img {
    width: 1.5em;
    height: 1.5em;
  }