.controls-container {
    padding: 20px;
}

.control-form {
    max-width: 500px;
    margin: 20px 0;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}

.form-group input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.submit-button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.submit-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.error-message {
    color: #dc3545;
    padding: 10px;
    margin: 10px 0;
    background: #ffe6e6;
    border-radius: 4px;
}

.success-message {
    color: #28a745;
    padding: 10px;
    margin: 10px 0;
    background: #e6ffe6;
    border-radius: 4px;
} 