.customer-management {
    padding: 2em;
    font-family: Arial, sans-serif;
  }
  
  .customer-management__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
  }
  
  .customer-management__title {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
  }
  
  .customer-management__new-user {
    background-color: #3b5998;
    color: white;
    border: none;
    padding: 0.75em 1.5em;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .customer-management__filters {
    display: flex;
    gap: 1em;
    margin-bottom: 2em;
  }
  
  .customer-management__status-filter {
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 0.25em;
    font-size: 0.875rem;
    min-width: 10em;
  }
  
  .customer-management__search {
    position: relative;
    flex-grow: 1;
  }
  
  .customer-management__search-input {
    width: 100%;
    padding: 0.5em;
    padding-right: 2em;
    border: 1px solid #ddd;
    border-radius: 0.25em;
    font-size: 0.875rem;
  }
  
  .customer-management__search-icon {
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
    height: 1em;
  }
  
  .customer-management__table-container {
    overflow-x: auto;
  }
  
  .customer-management__table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.875rem;
  }
  
  .customer-management__table th,
  .customer-management__table td {
    padding: 0.4em;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .customer-management__table th {
    background-color: #000;
    color: white;
    font-weight: normal;
  }
  
  .customer-management__table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .customer-management__action-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.25em;
  }

  .customer-hamburger {
    display: flex;
    padding: 0.8em 0em;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    justify-content: center;
    margin-right: 0.6em;
  }
  
  .customer-hamburger span {
    width: 25px;
    height: 3px;
    background-color: rgb(7, 7, 7);
    transition: all 0.3s ease;
  }
  
  .customer-management__menu-icon {
    width: 1em;
    height: 1em;
  }
  
  .customer-management__table td:last-child {
    text-align: center;
  }
  
  @media (max-width: 1024px) {
    .customer-management {
      padding: 1em;
    }
    
    .customer-management__filters {
      flex-direction: column;
    }
    
    .customer-management__search {
      width: 100%;
    }
  }