.hero-banner-slide {
    padding: 2em;
  }

  .hero-banner-title{
     display: flex;
     justify-content: space-between;
     align-items: center;
  }
  
  .title {
    font-size: 1.4rem;
  }
  
  .add-slide-btn {
    font-size: 1rem;
    padding: 0.5em 1em;
    background-color: #346cb0;
    color: #fff;
    border: none;
    border-radius: 0.3em;
    cursor: pointer;
  }
  
  .table {
    display: table;
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-header, .table-row {
    display: table-row;
    background-color: #f4f4f4;
    font-size: 0.9rem;
  }
  
  .table-header {
    font-weight: bold;
    background-color: #000;
    color: #fff;
  }
  
  .table-cell {
    display: table-cell;
    padding: 1em;
    text-align: left;
    border: 1px solid #ddd;

  }
  .table-cell:nth-of-type(1){
    width:5%;
  }
  .table-cell:nth-of-type(2){
    width: 42%;
  }
  
  .banner-image {
    width: 100px;
    height: auto;
  }
  
  .view-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .status {
    display: inline-block;
    padding: 0.3em 0.7em;
    border-radius: 0.3em;
    font-size: 0.9rem;
  }
  
  .status.active {
    background-color: #28a745;
    color: #fff;
  }
  
  .edit-btn, .delete-btn {
    font-size: 0.9rem;
    padding: 0.3em 0.7em;
    border: none;
    border-radius: 0.3em;
    cursor: pointer;
    margin-right: 0.5em;
  }
  
  .edit-btn {
    background-color: #346cb0;
    color: #fff;
  }
  
  .delete-btn {
    background-color: #e83e8c;
    color: #fff;
  }
  