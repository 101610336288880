.container{
    display: flex;
}

.inner-container{
    width: 100%;
}

.dashboard-outer-container{
    padding: 1.5em 2em;
    display: flex;
    flex-direction: column;
}

.welcome-text{
    color: #474747;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 0;
}

.dashboard-container{
    width:100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 0.063em 0.188em rgba(0,0,0,0.1);
}

.stats-title{
    display: flex;
    align-items: center;
    gap:0.3em;
}

.stats-filters{
    display: flex;
    gap:0.6em;
    min-width: 60%;
    justify-content: flex-end;
    align-items: center;
}

.filter-select{
   width:30%;
   max-width: 180px;
   padding: 0.4em 0.6em;
}

.stats-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top:4px solid #346cb0;
    border-bottom: 0.01px solid grey;
    padding: 0.5em 0.3em;
}


.stats-grid{
    width: 100%;
    display: grid;
    padding: 1.5em;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 1.5em;
    justify-content: center;
    justify-items: center;
}

.stats-card{
    width: 100%;
    padding: 1.2em 1em;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    transition: transform 0.2s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stats-card:hover {
    transform: translateY(-3px);
}

/* Card color variations */
.card-revenue { background-color: #2ecc71; }
.card-total-jobs { background-color: #3498db; }
.card-open-jobs { background-color: #e74c3c; }
.card-closed-jobs { background-color: #9b59b6; }
.card-progress-jobs { background-color: #f1c40f; }
.card-scheduled-jobs { background-color: #1abc9c; }
.card-canceled-jobs { background-color: #e67e22; }
.card-ticket-size { background-color: #34495e; }

.card-info{
    display: flex;
    flex-direction: column;
    gap: 0.8em;
}

.card-label {
    font-size: 1rem;
    opacity: 0.9;
}

.card-value{
    font-size: 1.6rem;
    font-weight: 700;
}

.card-icon{
    width: 3.5em;
    height: 3.5em;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-icon svg {
    width: 1.8em;
    height: 1.8em;
    color: #fff;
}



.dashboard-inner-container{
    width: 100%;
    display: flex;
    gap:2em;
    margin-top: 2em;
}

.dashboard-container-2{
    width: 50%;
}

.graph-container{
    width: 100%;
    object-fit: cover;
}

.table-container {
    width: 100%;
    margin-top:1.5em;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
    font-size: 0.9rem;
    padding: 0em 0.5em;
}

table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;

}

thead {
    background-color: #000;
    color: #fff;
}

th {
    padding: 12px 15px;
    text-align: left;
    font-weight: 600;
}

td {
    padding: 12px 15px;
    border-bottom: 1px solid #eee;
}

tr td:first-of-type{
    font-weight: 600;
}

tbody tr:nth-child(even) {
    background-color: #e5e2e2;
}

tbody tr:hover {
    background-color: #f5f5f5;
}


@media screen and (max-width: 768px) {
    
    
    th, td {
        padding: 8px 10px;
        font-size: 14px;
    }
}

@media (max-width:1000px) {
    .dashboard-container-2{
        width: 100%;
    }
    .dashboard-inner-container{
        flex-direction: column;
    }
    .table-container{
        padding: 0;
    }
}

.loading {
    text-align: center;
    padding: 20px;
    font-size: 1.2em;
    color: #666;
}

.error-message {
    padding: 16px;
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 8px;
    margin: 20px;
    border: 1px solid #f5c6cb;
}

.no-data {
    text-align: center;
    padding: 20px;
    color: #666;
    font-style: italic;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.stat-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stat-card h3 {
    margin: 0 0 10px 0;
    color: #333;
    font-size: 1.1em;
}

.stat-card p {
    margin: 0;
    font-size: 1.8em;
    font-weight: bold;
    color: #007bff;
}

.go-button {
    padding: 0.4em 1em;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease;
    height: 100%;
    min-height: 32px;
}

.go-button:hover {
    background-color: #218838;
}

.go-button:active {
    transform: translateY(1px);
}
