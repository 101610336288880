.table-container {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  margin-top: 20px;
  width: 100%;
}

.package-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);
}

.package-table th,
.package-table td {
  border: 0.0625em solid #e0e0e0;
  padding: 0.75em;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.package-table th:nth-child(1),
.package-table td:nth-child(1) {
  width: 12%;  /* Name of Package */
}

.package-table th:nth-child(2),
.package-table td:nth-child(2) {
  width: 10%;  /* Category */
}

.package-table th:nth-child(3),
.package-table td:nth-child(3) {
  width: 15%;  /* Includes */
}

.package-table th:nth-child(4),
.package-table td:nth-child(4) {
  width: 8%;  /* Average time */
}

.package-table th:nth-child(5),
.package-table td:nth-child(5),
.package-table th:nth-child(6),
.package-table td:nth-child(6) {
  width: 7%;  /* MRP and Offer Price */
}

.package-table th:nth-child(7),
.package-table td:nth-child(7),
.package-table th:nth-child(8),
.package-table td:nth-child(8) {
  width: 6%;  /* Discount and Rating */
}

.package-table th:nth-child(9),
.package-table td:nth-child(9) {
  width: 8%;  /* Inventory */
}

.package-table th:nth-child(10),
.package-table td:nth-child(10) {
  width: 15%;  /* Increased width for Actions */
  min-width: 160px; /* Ensure minimum width for buttons */
  position: sticky;
  right: 0;
  background-color: inherit;
}

.package-table th {
  background-color: #000000;
  color: #ffffff;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 600;
  white-space: normal;
  height: 50px;
  vertical-align: middle;
}

.package-table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.package-table tr:hover {
  background-color: #f0f0f0;
}

.package-table input {
  width: 100%;
  padding: 0.5em;
  border: 0.0625em solid #ddd;
  border-radius: 0.25em;
  font-size: 0.875rem;
  background: transparent;
}

.package-table input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0.25em rgba(74, 144, 226, 0.2);
}

.package-table input[type="number"] {
  text-align: right;
}

.action-buttons {
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  min-height: 2.5em;
  background: inherit;
}

.action-btn {
  padding: 0.35em 0.75em;
  border: none;
  border-radius: 0.25em;
  cursor: pointer;
  font-size: 0.8rem;
  color: white;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 45px;
  transition: all 0.25s ease;
  z-index: 1;
}

.action-btn.view {
  background-color: #10B981;
}

.action-btn.edit {
  background-color: #3B82F6;
}

.action-btn.delete {
  background-color: #EF4444;
}

.action-btn:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.action-btn:active {
  transform: translateY(1px);
}

.package-table th:nth-child(10) {
  background-color: #000000;
  z-index: 2;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px 20px 10px 0;
}

.upload-button, .add-package-button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:hover, .add-package-button:hover {
  background-color: #333333;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-content h2 {
  margin-bottom: 20px;
  color: #2c3e50;
  font-size: 1.5rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #4a5568;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: border-color 0.2s;
}

.form-group input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.submit-btn,
.cancel-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.submit-btn {
  background-color: #000000;
  color: white;
}

.cancel-btn {
  background-color: #e2e8f0;
  color: #4a5568;
}

.submit-btn:hover {
  background-color: #333333;
}

.cancel-btn:hover {
  background-color: #cbd5e0;
}

@media screen and (max-width: 1024px) {
  .table-container {
    margin: 1em;
  }
  
  .package-table th,
  .package-table td {
    padding: 0.5em;
  }
  
  .package-table th {
    font-size: 0.75rem;
  }
  
  .package-table input {
    font-size: 0.625rem;
    padding: 0.25em;
  }
}