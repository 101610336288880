.settings-container {
    padding: 2em;
}

.settings-title {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 2em;
}

.password-form-container {
    background: white;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 2em;
    max-width: 500px;
    margin: 0 auto;
}

.password-form-title {
    font-size: 1rem;
    color: #333;
    margin-bottom: 1.5em;
}

.password-form {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.profile-form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.form-group label {
    font-size: 0.875rem;
    color: #333;
}

.password-input {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.875rem;
}

.password-input:focus {
    outline: none;
    border-color: #0088cc;
    box-shadow: 0 0 0 2px rgba(0, 136, 204, 0.1);
}

.change-password-btn {
    background-color: #0088cc;
    color: white;
    border: none;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
    align-self: flex-start;
    margin-top: 0.5em;
}

.change-password-btn:hover {
    background-color: #0077b3;
}

.error-message {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: -0.5em;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .settings-container {
        padding: 1em;
    }
    
    .password-form-container {
        padding: 1.5em;
    }
}