.jobs-container{
    display: flex;
    flex-direction: column;
    padding: 1em;
    background-color: white;
}


.job-dashboard-container {
    padding: 1em;
    background-color: #e9ebeb;

  }
  
  .total-jobs {
    border-radius: 0.5em;
    padding: 0.5em;
  }
  

  .filter-section {
    background-color: white;
    padding: 1em;
    border-radius: 0.5em;
    margin-bottom: 2em;
  }
  
  .filter-header {
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0.5em;
    padding: 0.5em 0em;
    font-size: 1.2rem;
    border-bottom: 1px solid #666;
    cursor: pointer;
  }
  
  .filter-icon {
    width: 1.2em;
    height: 1.2em;
  }
  
  .filter-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px,1fr));
    gap: 1.5em;
    margin-bottom: 1em;
    background-color: #fff;
    border-bottom: 0.1px solid #d0cfcf;
    padding: 0.4em;
    padding-bottom: 1.5em;
  }
  
  .filter-item {
    display: flex;
    flex-direction: column;
    gap: 0em;
  }
  
  .filter-item label {
    font-size: 0.875rem;
    color: #333;
  }
  
  .filter-item input,
  .filter-item select {
    padding: 0.5em 0.7em;
    border: 1px solid #ddd;
    border-radius: 0.25em;
    font-size: 0.875rem;
  }

  .filter-item input::placeholder{
    color: #000;
  }
  
  .button-group {
    display: flex;
    gap: 1em;
    justify-content: center;
  }
  
  .search-btn,
  .clear-btn {
    padding: 0.75em 2em;
    border: none;
    border-radius: 0.25em;
    font-size: 0.875rem;
    cursor: pointer;
  }
  
  .search-btn {
    background-color: #2196F3;
    color: white;
  }
  
  .clear-btn {
    background-color: #FFC107;
    color: #333;
  }
  
  .jobs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    
  }
  
  .action-buttons {
    display: flex;
    gap: 1em;
  }
  
  .export-btn,
  .generate-btn {
    padding: 0.5em 1em;
    border: none;
    border-radius: 0.25em;
    font-size: 0.875rem;
    cursor: pointer;
  }
  
  .export-btn {
    background-color: #009688;
    color: white;
  }
  
  .generate-btn {
    background-color: #2196F3;
    color: white;
  }
  
  .jobs-table {
    overflow-x: auto;
    min-height: 40vh;
    height: fit-content;
    background-color: white;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th {
    background-color: #000;
    color: white;
    padding: 1em;
    text-align: center;
    font-size: 0.9rem;
    border-left: 0.5px solid white;
  }
  
  td {
    padding: 0.4em;
    border-bottom: 1px solid #ddd;
    font-size: 0.9rem;
    text-align: center;
    border-right: 0.3px solid #dfdede;
  }

  td:last-of-type{
    width: 13%;
    border-right: none;
  }


  
  .schedule-btn {
    background-color: #2196F3;
    color: white;
    border: none;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    font-size: 0.875rem;
    margin-top: 0.5em;
    cursor: pointer;
  }
  
  .time-slot {
    background-color: #2196F3;
    color: white;
    padding: 0.25em 0.5em;
    border-radius: 0.25em;
    display: inline-block;
    margin-top: 0.5em;
    font-weight: 700;
  }
  
  .category-tag {
    background-color: #009688;
    color: white;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    display: inline-block;
    font-weight: 700;
  }
  
  
  .job-action-btn {
    background-color: transparent;
    border: 1px solid #272a2c;
    color: #656566;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 1rem;
    
  }
  
  .dropdown{
    position: relative;
  }