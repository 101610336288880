.package-selector {
  max-width: 35em;
  margin: 0 auto;
  padding: 2em;
  font-family: Arial, sans-serif;
}

.selector-container {
  background: #ffffff;
  border-radius: 0.5em;
  padding: 2em;
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);
}

.offer-input {
  margin-bottom: 2em;
}

.offer-input label,
.packages-section label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.75em;
  color: #333;
}

.offer-input input {
  width: 100%;
  padding: 0.75em;
  font-size: 1rem;
  border: 0.0625em solid #ddd;
  border-radius: 0.25em;
}

.packages-container {
  border: 0.0625em solid #ddd;
  border-radius: 0.25em;
  padding: 1em;
  min-height: 6em;
}

.selected-packages {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-bottom: 0.5em;
}

.package-tag {
  display: inline-flex;
  align-items: center;
  background-color: #0066cc;
  color: white;
  padding: 0.5em 1em;
  border-radius: 0.25em;
  font-size: 0.875rem;
}

.remove-button {
  background: none;
  border: none;
  color: white;
  margin-left: 0.5em;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
  padding: 0 0.25em;
}

.remove-button:hover {
  opacity: 0.8;
}

.package-select {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  color: #333;
  padding: 0.5em 0;
}

.package-select:focus {
  outline: none;
}

.submit-button {
  background: #0066cc;
  color: white;
  border: none;
  border-radius: 0.25em;
  padding: 0.75em 1.5em;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 2em;
}

.submit-button:hover {
  background: #0052a3;
}