.jobs-container {
    padding: 20px;
    width: 100%;
    background-color: white;
    min-height: calc(100vh - 64px);
}

.jobs-header {
    background: white;
    padding: 24px 32px;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0,0,0,0.08);
    margin-bottom: 24px;
}

.search-section {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.search-filters {
    display: flex;
    gap: 16px;
    flex: 1;
}

.search-input {
    flex: 2;
    padding: 12px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.3s ease;
    min-width: 250px;
}

.search-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.1);
    outline: none;
}

.filter-select {
    flex: 1;
    min-width: 180px;
    padding: 12px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 14px;
    background-color: white;
    cursor: pointer;
    transition: all 0.3s ease;
}

.filter-select:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.1);
    outline: none;
}

.plus-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
}

.plus-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.plus-button:active {
    transform: scale(0.95);
}

.plus-button::after {
    content: "Create New Job";
    position: absolute;
    left: 50px;
    padding: 8px 12px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 4px;
    font-size: 14px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.plus-button:hover::after {
    opacity: 1;
    visibility: visible;
}

/* Table styles */
.jobs-table {
    width: 100%;
    table-layout: fixed;
    margin-bottom: 24px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    background-color: #000000;
    color: white;
    padding: 12px 8px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
}

td {
    padding: 12px 8px;
    border-bottom: 1px solid #edf2f7;
    font-size: 14px;
    line-height: 1.6;
}

.jobs-table td > div {
    margin-bottom: 8px;
}

.jobs-table td > div:last-child {
    margin-bottom: 0;
}

.jobs-table th:first-child,
.jobs-table td:first-child {
    padding-left: 32px;
}

.jobs-table th:last-child,
.jobs-table td:last-child {
    padding-right: 5px;
}

/* Status badges */
.status-badge {
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    margin: 4px 0;
}

.status-upcoming {
    background-color: #E8F5E9;
    color: #00A76F;
}

.status-cancelled {
    background-color: #FFE9E9;
    color: #FF4842;
}

.status-10 {
    background-color: #E8F5E9;
    color: #00A76F;
}

/* Action buttons */
.action-buttons-cell {
    display: flex;
    gap: 8px;
    padding: 4px 0;
    justify-content: flex-start;
    align-items: center;
}

.view-button,
.edit-button,
.delete-button {
    padding: 8px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    min-width: 70px;
    text-align: center;
}

.view-button {
    border-color: #007bff;
    background-color: #007bff;
    color: white;
    box-shadow: 0 2px 6px rgba(0, 145, 234, 0.3);
}

.edit-button {
    background-color: #00C853;
    color: white;
    box-shadow: 0 2px 6px rgba(0, 200, 83, 0.3);
}

.delete-button {
    background-color: #6c757d;
    color: white;
    transition: background-color 0.3s ease;
}

.delete-button:hover {
    background-color: #5a6268;
}

/* Enhanced hover effects */
.view-button:hover {
    color: white;
    background: #1f4e7f;
    transform: translateY(-2px);
    border-color: #0056b3;
}

.edit-button:hover {
    background-color: #00A243;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(36, 149, 83, 0.4);
}

.delete-button:hover {
    background-color: #B50000;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(213, 0, 0, 0.4);
}

/* Active state for buttons */
.view-button:active,
.edit-button:active,
.delete-button:active {
    transform: translateY(1px);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* Disabled state */
.view-button:disabled,
.edit-button:disabled,
.delete-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

/* Pagination styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    flex-wrap: wrap;
}

.pagination button {
    padding: 8px 16px;
    border: 1px solid #e0e0e0;
    background: white;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.3s ease;
    font-size: 14px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination button.active {
    background: #007bff;
    color: white;
    border-color: #007bff;
}

.pagination button:hover:not(:disabled):not(.active) {
    background: #f8f9fa;
    border-color: #007bff;
    color: #007bff;
}

.pagination-dots {
    color: #666;
    padding: 0 4px;
}

.pagination-info {
    margin-left: 16px;
    color: #666;
    font-size: 14px;
}

/* Loading and messages */
.loading {
    text-align: center;
    padding: 48px;
    font-size: 1.2em;
    color: #666;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0,0,0,0.08);
}

.error-message {
    padding: 16px;
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 8px;
    margin: 20px 0;
    border: 1px solid #f5c6cb;
    text-align: center;
    animation: fadeIn 0.3s ease-in;
}

.success-message {
    padding: 16px;
    background-color: #d4edda;
    color: #155724;
    border-radius: 8px;
    margin: 20px 0;
    border: 1px solid #c3e6cb;
    text-align: center;
    animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .search-filters {
        flex-direction: column;
    }

    .search-input,
    .filter-select {
        width: 100%;
    }

    .jobs-table {
        display: block;
        overflow-x: auto;
    }

    .action-buttons-cell {
        flex-direction: column;
        gap: 4px;
    }

    .view-button,
    .edit-button,
    .delete-button {
        width: 100%;
        text-align: center;
    }

    .pagination {
        gap: 4px;
    }

    .pagination button {
        padding: 6px 12px;
        min-width: 36px;
    }

    .pagination-info {
        width: 100%;
        text-align: center;
        margin-top: 12px;
        margin-left: 0;
    }
}

.jobs-table tr {
    height: auto;
    min-height: 80px;
}

.jobs-table tbody tr {
    background-color: white;
}

.jobs-table tbody tr:hover {
    background-color: #f8f9fa;
}

.no-jobs-message {
    text-align: center;
    padding: 2rem;
    background-color: rgb(217, 118, 118);
    border-radius: 8px;
    margin: 2rem 0;
    color: #010910;
    font-size: 1.1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
}

.modal-header {
    padding: 1rem;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-body {
    padding: 1rem;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.job-details {
    display: grid;
    gap: 1rem;
}

.detail-row {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 1rem;
    padding: 0.5rem;
    border-bottom: 1px solid #eee;
}

.label {
    font-weight: bold;
    color: #666;
}

.value {
    color: #333;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.form-actions button {
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.form-actions button[type="button"] {
    background-color: #6c757d;
    color: white;
}

.form-actions button[type="submit"] {
    background-color: #28a745;
    color: white;
}

.form-actions button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.success-animation-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    border-radius: 8px;
}

.main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.check-container {
    width: 6.25rem;
    height: 7.5rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
}

.check-background {
    width: 100%;
    height: calc(100% - 1.25rem);
    background: linear-gradient(to bottom right, #5de593, #41d67c);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
        0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    transform: scale(0.84);
    border-radius: 50%;
    animation: animateContainer 0.75s ease-out forwards 0.75s;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.check-background svg {
    width: 65%;
    transform: translateY(0.25rem);
    stroke-dasharray: 80;
    stroke-dashoffset: 80;
    animation: animateCheck 0.35s forwards 1.25s ease-out;
}

.check-shadow {
    bottom: calc(-15% - 5px);
    left: 0;
    border-radius: 50%;
    background: radial-gradient(closest-side, rgba(73, 218, 131, 1), transparent);
    animation: animateShadow 0.75s ease-out forwards 0.75s;
}

@keyframes animateContainer {
    0% {
        opacity: 0;
        transform: scale(0);
        box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }
    25% {
        opacity: 1;
        transform: scale(0.9);
        box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }
    43.75% {
        transform: scale(1.15);
        box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }
    62.5% {
        transform: scale(1);
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
    }
    81.25% {
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
    }
    100% {
        opacity: 1;
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
    }
}

@keyframes animateCheck {
    from {
        stroke-dashoffset: 80;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes animateShadow {
    0% {
        opacity: 0;
        width: 100%;
        height: 15%;
    }
    25% {
        opacity: 0.25;
    }
    43.75% {
        width: 40%;
        height: 7%;
        opacity: 0.35;
    }
    100% {
        width: 85%;
        height: 15%;
        opacity: 0.25;
    }
}

/* Button Variables */
:root {
    --green: #1ECD97;
    --gray: #bbbbbb;
}

/* Action Button Base Styles */
.view-button,
.edit-button,
.delete-button {
    outline: none;
    height: 28px;
    text-align: center;
    width: 80px;
    border-radius: 20px;
    background: #fff;
    letter-spacing: 0.5px;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.25s ease;
    text-transform: uppercase;
    border: 2px solid;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    color: #000000;
}

/* View Button Styles */
.view-button {
    border-color: #007bff;
    background-color: #007bff;
    color: white;
}

.view-button:hover {
    color: white;
    background: #0056b3;
    transform: translateY(-2px);
    border-color: #0056b3;
}

.view-button:active {
    letter-spacing: 2px;
    transform: translateY(1px);
}

/* Edit Button Styles */
.edit-button {
    border-color: var(--green);
}

.edit-button:hover {
    color: white;
    background: var(--green);
    transform: translateY(-2px);
}

.edit-button:active {
    letter-spacing: 2px;
    transform: translateY(1px);
}

/* Delete Button Styles */
.delete-button {
    border-color: #dc3545;
}

.delete-button:hover {
    color: white;
    background: #dc3545;
    transform: translateY(-2px);
}

.delete-button:active {
    letter-spacing: 2px;
    transform: translateY(1px);
}

/* Loading State */
.button-loading {
    width: 28px;
    border-color: var(--gray);
    border-width: 2px;
    font-size: 0;
    border-left-color: var(--green);
    animation: rotating 2s 0.25s linear infinite;
}

/* Success State */
.button-success {
    font-size: 13px;
    color: white;
    background: var(--green);
}

.button-success:after {
    font-family: 'FontAwesome';
    content: "\f00c";
}

/* Action Buttons Container */
.action-buttons {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    white-space: nowrap;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .action-buttons {
        flex-direction: column;
        gap: 8px;
    }

    .view-button,
    .edit-button,
    .delete-button {
        width: 100%;
    }
}

/* Set specific widths for each column */
.jobs-table th:nth-child(1),
.jobs-table td:nth-child(1) {
    width: 12%; /* Job ID */
}

.jobs-table th:nth-child(2),
.jobs-table td:nth-child(2) {
    width: 15%; /* Customer Name */
}

.jobs-table th:nth-child(3),
.jobs-table td:nth-child(3) {
    width: 12%; /* Mobile */
}

.jobs-table th:nth-child(4),
.jobs-table td:nth-child(4) {
    width: 10%; /* Date */
}

.jobs-table th:nth-child(5),
.jobs-table td:nth-child(5) {
    width: 10%; /* Service Cost */
}

.jobs-table th:nth-child(6),
.jobs-table td:nth-child(6) {
    width: 15%; /* Status */
}

.jobs-table th:nth-child(7),
.jobs-table td:nth-child(7) {
    width: 26%; /* Actions */
}

/* Make table responsive */
@media screen and (max-width: 1200px) {
    .jobs-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
}

/* Add these styles for the job details cards */
.detail-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.detail-section {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
}

.detail-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.detail-section h3 {
    color: #346cb0;
    margin: 0 0 20px 0;
    padding-bottom: 10px;
    border-bottom: 2px solid #eee;
    font-size: 1.2rem;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
}

.detail-label {
    color: #666;
    font-weight: 500;
    font-size: 0.9rem;
}

.detail-value {
    color: #333;
    font-weight: 600;
    font-size: 0.95rem;
}

.status-badge {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
    text-transform: uppercase;
}

.status-closed {
    background-color: #4CAF50;
    color: white;
}

.status-open {
    background-color: #2196F3;
    color: white;
}

.status-cancelled {
    background-color: #f44336;
    color: white;
}

.status-in_progress {
    background-color: #FF9800;
    color: white;
}

.status-scheduled {
    background-color: #9C27B0;
    color: white;
}

/* Modal styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #f5f5f5;
    border-radius: 12px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.modal-header {
    background: #346cb0;
    color: white;
    padding: 20px;
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
}

.close-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.modal-body {
    padding: 20px;
}

/* Responsive design */
@media (max-width: 768px) {
    .detail-grid {
        grid-template-columns: 1fr;
    }
    
    .modal-content {
        width: 95%;
        margin: 10px;
    }
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-height: 70vh;
    overflow-y: auto;
    padding: 20px;
}

.form-section {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
}

.form-section h3 {
    margin-bottom: 15px;
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group textarea {
    height: 80px;
    resize: vertical;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 15px;
    border-top: 1px solid #ddd;
}

.form-actions button {
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.form-actions button[type="submit"] {
    background-color: #4CAF50;
    color: white;
    border: none;
}

.form-actions button[type="button"] {
    background-color: #f44336;
    color: white;
    border: none;
} 